import { nonReactiveMembersMixin } from '@/mixins/nonReactiveMembersMixin';
import gql from 'graphql-tag';
import { debounce, get } from 'lodash';

const WORD_CONTAINER_TAG = 'sb-dict-container';
const WORD_DEFINITION_TAG = 'sb-dict-word';
const WORD_DEFINITION_FRAGMENT_TAG = 'sb-dict-word-fragment';
const LINE_TAG = 'sb-dict-line';

export const wordDefinitionsMixin = (watchPath, containerQuery) => ({
  mixins: [
    nonReactiveMembersMixin(() => ({
      WORD_CONTAINER_TAG,
      WORD_DEFINITION_FRAGMENT_TAG,
      WORD_DEFINITION_TAG,
      LINE_TAG,
    })),
  ],

  data() {
    return {
      __wordDefinitionMixin__uniqueWords: [],
      __wordDefinitionMixin__content: null,
      __wordDefinitionMixin__wordEntries: null,
    };
  },

  mounted() {
    const unwatch = this.$watch(
      watchPath.split('.')[0],
      () => requestAnimationFrame(() => this.prepareWordDefinitionDOM()),
      {
        immediate: true,
        deep: true,
      },
    );
  },

  watch: {
    '$store.state.doShowWordDefinitions': {
      handler(value) {
        if (value) {
          this.addClickHandlers();
        }

        if (!value) {
          this.removeClickHandlers();
        }
      },
      immediate: true,
    },
  },

  methods: {
    prepareWordDefinitionDOM: debounce(
      function debouncedPrepareWordDefinitionDOM() {
        let noOfLines;
        const contentContainer = document.querySelector(containerQuery);
        const dom = contentContainer.cloneNode(true);

        const { fragment, uniqueWords } = createDefinedWordsDom(dom);

        contentContainer.replaceChildren(fragment);

        (this.__wordDefinitionMixin__uniqueWords ??= []).push(
          ...Array.from(new Set(uniqueWords.map(getWordVariants).flat())),
        );

        const wrappedWords = Array.from(
          document.querySelectorAll(`.${WORD_DEFINITION_TAG}`),
        );

        if (this.__wordDefinitionMixin__doWrapLines) {
          noOfLines = wrapLines(contentContainer);
        }

        (this.__wordDefinitionMixin__uniqueWords ??= []).push(
          ...Array.from(
            new Set(
              wrappedWords
                .map((el) => el.dataset.word)
                .map(getWordVariants)
                .flat(),
            ),
          ),
        );

        this.$apollo
          .query({
            variables: {
              first: this.__wordDefinitionMixin__uniqueWords.length,
              filter: {
                word: {
                  mode: 'INSENSITIVE',
                  in: Array.from(
                    new Set(
                      this.__wordDefinitionMixin__uniqueWords
                        .map((word) => [
                          word,
                          word.toLowerCase(),
                          word.replaceAll(SPECIAL_CHARS_REGEX, ' ').trim(),
                        ])
                        .flat(),
                    ),
                  ),
                },
              },
            },
            query: gql`
              query WordDefinitions($first: Int, $filter: WordEntriesFilter) {
                wordEntries(first: $first, filter: $filter) {
                  edges {
                    node {
                      id
                      word
                      relations {
                        wordId
                        relation
                        match
                        word {
                          id
                          definitions {
                            id
                            audio
                            content
                            examples
                            image
                            pos
                          }
                        }
                      }
                      definitions {
                        id
                        audio
                        content
                        examples
                        image
                        pos
                      }
                    }
                  }
                }
              }
            `,
          })
          .then(({ data }) => {
            this.__wordDefinitionMixin__wordEntries =
              data.wordEntries.edges.map(({ node }) => node);

            if (this.$store.state.doShowWordDefinitions) {
              requestAnimationFrame(() => this.addClickHandlers());
            }
          });

        requestAnimationFrame(() =>
          this.__wordDefinitionMixin__onDOMMutation?.({ noOfLines }),
        );
      },
      300,
    ),

    handleWordClick(event) {
      const word = event.currentTarget.dataset.word;
      const match = this.__wordDefinitionMixin__wordEntries?.find((entry) =>
        [
          entry.word === word,
          entry.word.toLowerCase() === word,
          entry.word === word.replaceAll(SPECIAL_CHARS_REGEX, ' ').trim(),
          entry.diminutive?.includes(entry),
          entry.plural?.includes(entry),
          entry.diminutivePlural?.includes(entry),
        ].some(Boolean),
      );

      this.$store.state.doShowWordDefinitionInfo = !!match;
      this.$store.state.activeWordDefinition = match;
    },

    removeClickHandlers() {
      document
        .querySelectorAll(
          `.${WORD_DEFINITION_TAG},.${WORD_DEFINITION_FRAGMENT_TAG}`,
        )
        .forEach((node) => {
          node.classList.remove('clickable');
          node.removeEventListener('click', this.handleWordClick);
        });
    },

    addClickHandlers() {
      const query = (this.__wordDefinitionMixin__wordEntries || [])
        .map((node) => {
          const possibleMatches = Array.from(
            new Set([
              node.word,
              node.word.toLowerCase(),
              node.word.replaceAll(SPECIAL_CHARS_REGEX, ' ').trim(),
            ]),
          );

          return possibleMatches.map((e) =>
            [
              `.${WORD_DEFINITION_TAG}[data-word="${e}"]`,
              `.${WORD_DEFINITION_FRAGMENT_TAG}[data-word="${e}"]`,
            ].join(','),
          );
        })
        .join(', ');

      if (!query) return;

      document.querySelectorAll(query).forEach((node) => {
        node.classList.add('clickable');
        node.addEventListener('click', this.handleWordClick);
      });
    },
  },
});

const WORD_END_REGEX = /[\n\r\t ]+$/m;
const SPLIT_SPACE_REGEX = /(\S+\s+)/;
const SPECIAL_CHARS_REGEX = /[ '`“”‘!@#$%^&*()_+\=\[\]{};:"\\|,.<>\/?~-]/g;
const BLOCK_OR_BREAK_ELEMENTS = ['DIV', 'P', 'LI', 'TABLE', 'UL', 'OL', 'BR'];

/**
 * This function exists to extract all text and formatting dom nodes, and determine all separate words from their content.
 * It assumes the wordpress wysiwyg editor structure:
 * !!!This means we expect an array of <p> elements at the top level, and both text nodes and formatting elements as child nodes!!!
 *
 * Supporting more complex dynamic content structure is *super* complex for this particular use case,
 * due to a myraid of potentially destructive edge cases, so this method adheres to DTSTTCPW.
 */
function createDefinedWordsDom(dom) {
  const fragment = document.createDocumentFragment();

  function prepareWords(parent) {
    const clone = parent.cloneNode();

    parent.childNodes.forEach((node) => {
      const { nodeType, textContent } = node;
      const isTextNode = nodeType === 3;

      if (isTextNode) {
        clone.append(
          ...textContent
            .split(SPLIT_SPACE_REGEX)
            .map((str) => {
              if (!str.trim().length) return str;
              const span = document.createElement('span');
              span.innerText = str;
              span.classList.add(WORD_DEFINITION_TAG);
              span.setAttribute(
                'data-word',
                str.toLowerCase().replaceAll(SPECIAL_CHARS_REGEX, ' ').trim(),
              );
              span.querySelectorAll('br').forEach((br) => br.remove());
              return span;
            })
            .filter(Boolean),
        );
      } else {
        clone.append(prepareWords(node));
      }
    });

    return clone;
  }

  const uniqueWords = [];

  [...dom.children].forEach((node) => {
    const processed = prepareWords(node);
    const tuples = [];
    let previous = null;

    processed.querySelectorAll(`.${WORD_DEFINITION_TAG}`).forEach((node) => {
      const prevContent = previous?.textContent;

      let isTable = false;
      let parent = node.parentElement;

      while (parent && parent !== dom) {
        if (parent.nodeName === 'TABLE') {
          isTable = true;
          parent = null;
          continue;
        }
        parent = parent.parentElement;
      }

      const isNewLineStart =
        !prevContent ||
        (isTable && !node.previousElementSibling) ||
        (isTable && !node.parentElement?.previousElementSibling) ||
        BLOCK_OR_BREAK_ELEMENTS.includes(
          node.previousElementSibling?.nodeName,
        ) ||
        BLOCK_OR_BREAK_ELEMENTS.includes(
          node.parentElement.previousElementSibling?.nodeName,
        );

      const isNewWordStart =
        isNewLineStart || !prevContent || WORD_END_REGEX.test(prevContent);

      if (!isNewLineStart && !isNewWordStart) {
        tuples[tuples.length - 1].push(node);
      } else {
        tuples.push([node]);
      }

      previous = node;
    });

    tuples.forEach((tuple) => {
      if (tuple.length < 2) return;
      const combined = tuple
        .map((node) => node.textContent)
        .join('')
        .trim();

      tuple.forEach((span, index, arr) => {
        span.dataset.word = combined.toLowerCase();
        span.classList.replace(
          WORD_DEFINITION_TAG,
          WORD_DEFINITION_FRAGMENT_TAG,
        );

        const isFirst = index === 0;
        const isLast = index === arr.length - 1;

        if (isFirst || isLast) {
          span.classList.add(isFirst ? 'v_start' : 'v_end');
        }

        if (isLast) {
          uniqueWords.push(combined);
        }
      });
    });

    fragment.append(processed);
  });

  return { fragment, uniqueWords };
}

function wrapLines(container) {
  let totalLines = 0;

  container.querySelectorAll('p').forEach((p) => {
    if (!p.children.length) return;

    const virtualLines = [[]];
    let referenceOffset = p.firstElementChild.offsetTop;

    p.children.forEach((child) => {
      if (child.nodeName !== 'BR' && child.offsetTop !== referenceOffset) {
        virtualLines.push([]);
        referenceOffset = child.offsetTop;
      }

      virtualLines[virtualLines.length - 1].push(child);
    });

    const lines = virtualLines.map((line) => {
      const div = document.createElement('div');
      div.classList.add(LINE_TAG);
      div.append(...line);
      return div;
    });

    totalLines += lines.length;
    p.replaceChildren(...lines);
  });

  return totalLines;
}

function getWordVariants(word) {
  return [
    word,
    word.toLowerCase(),
    word.replaceAll(SPECIAL_CHARS_REGEX, ' ').trim(),
  ];
}
